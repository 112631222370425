@font-face {
    font-family: Ibm;
    src: url("./_shared/fonts/IBMPlexSans-Regular.ttf");
    font-weight: 400;
}
@font-face {
    font-family: Ibm;
    src: url("./_shared/fonts/IBMPlexSans-Medium.ttf");
    font-weight: 500;
}
